import { Component } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-global-toast',
  templateUrl: './global-toast.component.html',
  styleUrls: ['./global-toast.component.scss']
})

export class GlobalToastComponent {
  message$ = this.toastService.message$;
  
  constructor(private toastService: ToastService) {}
}
