import { Component } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss']
})

export class GlobalLoaderComponent {
  message$ = this.loaderService.message$;
  
  constructor(private loaderService: LoaderService) {}
}
